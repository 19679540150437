.p-tabview-ink-bar {
  display: none !important;
}

p-dropdown {
  display: flex;
  align-items: center;
  height: 32px;
  font-family: Source Sans Pro, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: var(--font-size-4);
  line-height: 18px;
  color: var(--color-grey-1);
  border-radius: 2px;
  background: var(--color-grey-2);

  p-dropdownitem {
    font-size: var(--font-size-12);

    li {
      margin-bottom: 6px !important;
    }
  }

  .p-dropdown.p-focus,
  .p-dropdown.p-dropdown-open {
    .p-dropdown-label {
      color: var(--color-primary);
    }
  }
}

.p-dropdown {
  min-width: auto;

  .p-highlight {
    color: var(--color-primary);
  }

  .p-shadow {
    box-shadow: none;
  }

  .p-dropdown-panel {
    max-height: 250px;
    overflow-y: auto;
    background: var(--color-white);
    border-radius: 0px 0px 8px 8px;
    -webkit-box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.15);
    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.15);
  }

  label {
    padding-left: 10px;
    padding-top: 7px !important;
    padding-bottom: 7px !important;
  }
}

.pi-chevron-down {
  background: url('../../images/icons/select_arrow.svg');
  background-repeat: no-repeat;
  background-position-y: 6px;
  width: 30px;
  height: 30px;
  background-size: 7px 5px;
}

.p-dropdown-panel .p-dropdown-item {
  padding: 0.15em 0.25em;
  font-size: var(--font-size-4);
}

p-calendar {
  position: relative;
  top: -13px;

  span.p-disabled {
    color: #c4c4c4 !important;
  }

  & > .p-calendar {
    .p-datepicker {
      .p-datepicker-group {
        display: block;
        padding: 0 20px;
      }
    }

    .p-datepicker-month {
      font-size: var(--font-size-7);
      text-transform: capitalize;
      font-weight: var(--font-weight-semibold);
      padding-right: 0;
      margin-right: 5px;
    }

    .p-datepicker-year {
      font-size: var(--font-size-6);
      font-weight: var(--font-weight-semibold);
      padding-left: 0;
    }

    .p-datepicker-header {
      margin-right: 5px;
      font-family: Source Sans Pro, sans-serif;
      line-height: 20px;
      text-align: center;
      color: #6f6f6f;
      padding-bottom: 10px;

      .p-datepicker-prev {
        width: 22px;
        height: 22px;
        margin-top: 4px;

        chevronlefticon {
          right: 6px;
          width: 9px;
        }

        background-color: #f3f3f3;
        cursor: pointer;
        border-radius: 2px;
      }

      .p-datepicker-next {
        width: 22px;
        height: 22px;
        margin-top: 4px;

        chevronrighticon {
          right: 6px;
          width: 9px;
        }

        cursor: pointer;
        background-color: #f3f3f3;
        border-radius: 2px;
      }
    }

    table {
      border-collapse: separate;
      border-spacing: 0;

      th {
        font-family: Source Sans Pro, sans-serif;
        font-style: normal;
        font-weight: var(--font-weight-light);
        font-size: var(--font-size-6);
        color: var(--color-grey-1);
        padding: 0;
        padding-bottom: 10px;
      }

      td {
        position: relative;

        &.p-datepicker-current-day {
          position: relative;
          z-index: 1;
        }

        & > a,
        & > span {
          font-family: Source Sans Pro, sans-serif;
          font-style: normal;
          font-weight: normal;
          border-radius: 2px;
          font-size: var(--font-size-4);
          line-height: 30px;
          text-align: center;
          color: var(--color-grey-1);
          width: 35px;
          height: 35px;
          padding: 0;
          margin: auto;
          margin: 0;

          span {
            width: 100%;
            height: 100%;
          }
        }

        .backSelect {
          display: block;
          background-color: var(--color-primary);
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          border-radius: 2px;
          color: white;
          z-index: 10;
        }

        .between {
          &:before {
            content: '';
            width: 100%;
            position: absolute;
            z-index: -1;
            background-color: rgba(#33cc66, 0.1);
            left: 0;
            height: 100%;
            top: 0;
          }
        }

        &.sub {
          background: #e5e5e5;
          border-radius: 0;

          &.over {
            span {
              border-radius: 2px;
              position: relative;
              z-index: 1;

              span {
                position: absolute;
                left: 0;
                top: 0;
                color: white;

                &:before {
                  content: none;
                }
              }

              background-color: var(--color-primary);
            }
          }

          &.first {
            border-top-left-radius: 2px;
            border-bottom-left-radius: 2px;
          }

          &.last {
            border-top-right-radius: 2px;
            border-bottom-right-radius: 2px;
          }
        }

        cursor: pointer;
      }

      .p-disabled {
        background-color: var(--color-white);

        span {
          cursor: not-allowed;
          font-family: Source Sans Pro, sans-serif;
          font-style: normal;
          font-weight: normal;
          font-size: var(--font-size-4);
          color: var(--color-grey-4);
        }
      }

      .p-ripple {
        border-radius: 50%;
        border: 1px solid transparent;
      }

      .p-ripple:not(.p-disabled):hover {
        border-radius: 50%;
        color: var(--color-primary);
        border-color: var(--color-primary);
      }
    }
  }
}

.p-multiselect-panel {
  background: #ffffff;
  -webkit-box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.15);
  border-radius: 0px 0px 8px 8px;
}

p-multiselect {
  font-family: Source Sans Pro, sans-serif;
  font-style: normal;
  font-weight: var(--font-weight-semibold);
  font-size: var(--font-size-4);
  line-height: 18px;
  color: var(--color-grey-1);
  box-sizing: border-box;
  border-radius: 20px;
  height: 32px;
  display: block;

  .p-multiselect-header {
    display: none;
  }

  .p-checkbox {
    margin-right: 8px;
  }

  .p-checkbox-box {
    width: 15px !important;
    height: 15px !important;
    background-color: var(--color-white);
    border: 1px solid #c4c4c4;
  }

  .p-checkbox-box.p-highlight {
    background: var(--color-primary);
    position: relative;
    border-color: transparent;
  }

  .p-checkbox-box.p-highlight::before {
    content: url('../../images/icons/check.svg');
    width: 7px;
    height: 5px;
    bottom: 10px;
    left: 3px;
    position: absolute;
  }

  .p-multiselect-label {
    padding-left: 15px !important;
    padding-top: 7px !important;
    font-weight: var(--font-weight-semibold);
    font-size: var(--font-size-4);
    line-height: 18px;
    color: var(--color-grey-1);
  }

  .p-multiselect.p-multiselect-open {
    .p-multiselect-label {
      color: var(--color-primary) !important;
    }
  }

  &.multiselet-filter-table-admin {
    .p-multiselect-item {
      display: flex;
    }
  }
}

.p-dropdown .p-dropdown-label {
  padding-right: 1.6em;
}

.p-multiselect-panel .p-multiselect-item {
  padding: 5px 0.25em;
  font-family: Source Sans Pro, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: var(--font-size-4);

  color: var(--color-grey-1);
}

.p-skeleton {
  background-color: #f6f6f6;
  border-radius: 25px;
}

.p-column-filter-overlay {
  background-color: #ffffff;
  color: #495057;
  border: 0 none;
  border-radius: 6px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  min-width: 12.5rem;

  div:first-of-type {
    span {
      font-weight: 700 !important;
    }
  }

  .p-column-filter-constraints {
    padding-bottom: 1rem;
  }

  .p-column-filter-buttonbar {
    justify-content: space-around;
    padding-bottom: 1rem;

    .p-button-sm {
      border: none;
      box-shadow: 0px 4px 4px #dadada;
      border-radius: 100px;
      padding-right: 10px;
      padding-left: 10px;
      transition: all 0.2s ease-in-out;
      background-color: var(--color-button);
      color: var(--color-white);

      &:hover {
        background-color: var(--color-hover);
      }
    }

    .p-button-outlined {
      color: var(--color-primary);

      &::after {
        background-color: var(--color-primary);
      }

      border: none;
      box-shadow: 0px 4px 4px #dadada;
      border-radius: 100px;

      padding-right: 10px;
      padding-left: 10px;
      background-color: var(--color-white);
      transition: all 0.2s ease-in-out;
      position: relative;

      &::after {
        content: '';
        width: 100%;
        height: 100%;
        margin-left: -10px;
        margin-right: -10px;
        border-radius: 100px;
        display: block;
        position: absolute;
        top: 0;
        opacity: 0;
        transition: all 0.2s ease-in-out;
      }

      &:hover::after {
        opacity: 0.04;
      }
    }
  }
}
